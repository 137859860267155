import React from 'react';
import { Carousel } from 'react-bootstrap';
import slider_web from '../../images/slider_web.jpg';
import slider_mobile from '../../images/slider_mobile.jpg';
import slider_management from '../../images/slider_management.jpg';
import slider_digital from '../../images/slider_digital.jpg';

class SiteCarousel extends React.Component {
    render(){
        return (
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slider_web}
                    alt="Web Development"
                    />
                    <Carousel.Caption>
                    {/* <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slider_mobile}
                    alt="Mobile Development"
                    />

                    <Carousel.Caption>
                    {/* <h3>Third slide label</h3>
                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slider_digital}
                    alt="Digital Transformation"
                    />
                    <Carousel.Caption color="#333"  >
                    {/* <h3>Digital Transformation</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slider_management}
                    alt="Project Management"
                    />
                    <Carousel.Caption>
                    {/* <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        )
    }
}
export default SiteCarousel;


