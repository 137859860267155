import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { RiUserFollowFill } from "react-icons/ri";
import { BiBullseye } from "react-icons/bi";
import { faHandHoldingUsd } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';
class WhyUs extends React.Component {
   
    render(){
        
        return (
            <div id="why-us">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <ScrollAnimation animateIn="animate__slideInUp" delay={1000} offset={0} animateOnce={true}>
                                <span className="h2">Why Veracity?</span>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="animate__slideInUp" animateOut='fadeOutLeft' delay={1500} offset={0} animateOnce={true} >
                                <p>
                                    Veracity InfoTech offers domain and technical extertise that produces high performance and highly reliable systems to help you run your business effectively and increase your revenue.
                                </p>
                            </ScrollAnimation>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={4}>
                            <ScrollAnimation animateIn="animate__slideInRight"  delay={2000} offset={0} animateOnce={true}>
                                <Row className="mb-3">
                                    <FontAwesomeIcon  icon={faHandHoldingUsd} size="4x" color="#1778c6"/>
                                </Row>
                                <Row>
                                    <h5>Holistic Approach</h5>
                                </Row>
                                <Row>
                                    We offerts end-to-end IT services : Design, Development, Quality Assurance, Maintance and Support.
                                </Row>
                            </ScrollAnimation>
                        </Col>
                        <Col xs={12} sm={4}>
                            <ScrollAnimation animateIn="animate__slideInRight" delay={2500} offset={0} animateOnce={true}>
                                <Row className="mb-3">
                                    <RiUserFollowFill size="4em" fill="#1778c6" />
                                </Row>
                                <Row>
                                    <h5>Industrial Proficient</h5>
                                </Row>
                                <Row>
                                    We have dedicated team of exterts in every segments of product development life cycle.
                                </Row>
                            </ScrollAnimation>
                        </Col>
                        <Col xs={12} sm={4}>
                            <ScrollAnimation animateIn="animate__slideInRight" delay={3000} offset={0} animateOnce={true}>
                                <Row className="mb-3">
                                    <BiBullseye size="4em" fill="#1778c6"/>
                                </Row>
                                <Row>
                                    <h5>Customer Centric</h5>
                                </Row>
                                <Row>
                                    We always put customer's interest first, above all.We aim to provide competent advice as per the customer needs.
                                </Row>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default WhyUs