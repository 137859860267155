import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';   
import HexagoneCard from '../components/HexagoneCard';

class OurValues extends React.Component {
   
    render(){
        
        return (
            <div id="our-values" className="our-values">
                <Container fluid>
                    <Row>
                        {/* <Col sm={4} xs={12}>
                            
                        </Col> */}
                        <Col xs={12} className="d-flex justify-content-center">
                            <HexagoneCard/>
                        </Col>
                    </Row>
                </Container>
                
            </div>
        )
    }
}

export default OurValues