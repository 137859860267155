import React from 'react';
import { Container, Row, Col, CardDeck, Card } from 'react-bootstrap';
import '../../css/card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3, faReact, faAngular, faJava, faPhp, faApple, faAndroid, faAws, faWindows, faBootstrap, faJoomla, faJs, faWordpress, faDocker, faGit, faJira, faJenkins, faGoogle, faBitbucket, faConfluence, faMixer } from "@fortawesome/free-brands-svg-icons";
import { faTasks, faUserTie } from '@fortawesome/fontawesome-free-solid';

class Services extends React.Component {
    render(){
        return (
            <div id="services" className="text-center">
                <h1>Our Services</h1>
                <CardDeck>
                    <Container>
                        <Row>
                            <Col xs={12} sm={6}>
                                <div class="card-flip" ontouchstart="this.classList.toggle('hover');">
                                    <div class="mainflip">
                                        <div class="frontside">
                                            <Card>
                                                <Card.Body>
                                                    <i className="fa fa-sitemap fa-4x" aria-hidden="true"></i>
                                                    <Card.Title>Web Design and Development</Card.Title>
                                                    <Card.Text>
                                                        We at Veracity InfoTech offers extensive expertise in Web Design and Web Development which includes both Frontend and Backend
                                                        Development.We have versatile capabilities in Frontend Tehcnologies, Web Technologies, Portal Technologies, API Developments etc.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div class="backside">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Web Design and Development Capabilities</Card.Title>
                                                    <Card.Text>
                                                        <Container className="pt-4">
                                                            <Row>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faHtml5} size="2x" color="#1778c6"/>
                                                                    <p>Html5</p>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faCss3}   size="2x" color="#1778c6"/>
                                                                    <p>CSS</p>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faBootstrap} size="2x" color="#1778c6"/>
                                                                    <p>Bootstrap</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faJs} size="2x" color="#1778c6"/>
                                                                    <p>Java Script</p>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faReact} size="2x" color="#1778c6"/>
                                                                    <p>React JS</p>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faAngular} size="2x" color="#1778c6" />
                                                                    <p>AgularJS</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={3}>
                                                                    <FontAwesomeIcon icon={faJava} size="2x" color="#1778c6"/>
                                                                    <p>Java</p>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <FontAwesomeIcon icon={faPhp} size="2x" color="#1778c6"/>
                                                                    <p>PHP</p>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <FontAwesomeIcon icon={faWordpress} size="2x" color="#1778c6"/>
                                                                    <p>Wordpress</p>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <FontAwesomeIcon icon={faJoomla} size="2x" color="#1778c6"/>
                                                                    <p>Joomla</p>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div class="card-flip" ontouchstart="this.classList.toggle('hover');">
                                    <div class="mainflip">
                                        <div class="frontside">
                                            <Card>
                                                <Card.Body>
                                                    <i className="fa fa-mobile fa-4x" aria-hidden="true"></i>
                                                    <Card.Title>Mobile Development</Card.Title>
                                                    <Card.Text>
                                                        We have a team of expert app developers who provide solutions for cross-platform application development that guarantees profit
                                                        and rapid business growth.Cross-channel solutions keep you updated with market trends.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div class="backside">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Mobile Development Capabilities</Card.Title>
                                                    <Card.Text>
                                                        <Container className="pt-4">
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <FontAwesomeIcon icon={faApple} size="2x" color="#1778c6"/>
                                                                    <p>iOS Apps</p>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <FontAwesomeIcon icon={faAndroid} size="2x" color="#1778c6"/>
                                                                    <p>Android Apps</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <FontAwesomeIcon icon={faReact} size="2x" color="#1778c6"/>
                                                                    <p>React Native Apps</p>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <FontAwesomeIcon icon={faMixer} size="2x" color="#1778c6"/>
                                                                    <p>Hybrid Apps</p>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <div class="card-flip" ontouchstart="this.classList.toggle('hover');">
                                    <div class="mainflip">
                                        <div class="frontside">
                                            <Card>
                                                <Card.Body>
                                                    <i className="fa fa-cloud fa-4x" aria-hidden="true"></i>
                                                    <Card.Title>Digital Transformation</Card.Title>
                                                    <Card.Text>
                                                        If you have legacy system that needs tranformation, Don't worry we provide end-to-end digital transformation of your application
                                                        or your company's entire eco system by using cutting edge tools and technologies.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div class="backside">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Digital Transformation Capabilities</Card.Title>
                                                    <Card.Text>
                                                        <Container className="pt-4">
                                                            <Row>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faAws} size="2x" color="#1778c6"/>
                                                                    <p>AWS</p>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faWindows} size="2x" color="#1778c6"/>
                                                                    <p>Azure</p>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <FontAwesomeIcon icon={faGoogle} size="2x" color="#1778c6"/>
                                                                    <p>GCP</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <FontAwesomeIcon icon={faJenkins} size="2x" color="#1778c6"/>
                                                                    <p>Jenkins</p>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <FontAwesomeIcon icon={faDocker} size="2x" color="#1778c6"/>
                                                                    <p>Docker</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={3}>
                                                                    <FontAwesomeIcon icon={faGit} size="2x" color="#1778c6"/>
                                                                    <p>Git</p>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <FontAwesomeIcon icon={faBitbucket} size="2x" color="#1778c6"/>
                                                                    <p>Bit bucket</p>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <FontAwesomeIcon icon={faConfluence} size="2x" color="#1778c6"/>
                                                                    <p>Confluence</p>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <FontAwesomeIcon icon={faJira} size="2x" color="#1778c6"/>
                                                                    <p>JIRA</p>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div class="card-flip" ontouchstart="this.classList.toggle('hover');">
                                    <div class="mainflip">
                                        <div class="frontside">
                                            <Card>
                                                <Card.Body>
                                                    <i class="fa fa-bar-chart fa-4x" aria-hidden="true"></i>
                                                    <Card.Title>Project Management</Card.Title>
                                                    <Card.Text>
                                                        Project Management nowdays is crucial and most important aspect in IT industry. We have team of high skill certified leaders 
                                                        and executives who can execute every stage of software development life cycle very effectively.
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div class="backside">
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Project Management Capabilities</Card.Title>
                                                    <Card.Text>
                                                        <Container className="pt-4">
                                                                <Row>
                                                                    <Col xs={6}>
                                                                        <FontAwesomeIcon icon={faTasks} size="2x" color="#1778c6"/>
                                                                        <p>Project Execution</p>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <FontAwesomeIcon icon={faUserTie} size="2x" color="#1778c6"/>
                                                                        <p>Agile Coaching and Transformation</p>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CardDeck>
            </div>
        )
    }
}

export default Services