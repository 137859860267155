import React from 'react';
import '../../css/hexagone.css'
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDigitalOcean } from '@fortawesome/free-brands-svg-icons';
import { faCheck, faHandHoldingHeart, faHandshake, faUserShield } from '@fortawesome/fontawesome-free-solid';

class HexagoneCard extends React.Component {
   
    render(){
        
        return (
            <div id="hexagone-card" className="d-none d-lg-block">
                <Container fluid>
                    <Row>
                        <Col xs={12} className="text-center">
                            <div className="hexagon">
                                <div className="inner">
                                    <div className="core-value-icon">
                                        <FontAwesomeIcon icon={faDigitalOcean} size="4x" color="#1778c6"/>
                                    </div>
                                    <div className="core-value-title">
                                        Be Adaptative
                                    </div>
                                </div>
                            </div>
                            <div className="core-value-content content-top">
                                <ul className="fa-ul">
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content
                                    </li>
                                    <li>
                                        <span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pt-4 pb-4">  
                        <Col sm={4} xs={12} className="text-center">
                            <div className="hexagon">
                                <div className="inner">
                                    <div className="core-value-icon">
                                        <FontAwesomeIcon icon={faHandshake} size="4x" color="#1778c6"/>
                                    </div>
                                    <div className="core-value-title">
                                        Be Committed
                                    </div>
                                </div>
                            </div>
                            <div className="core-value-content content-left">
                                <ul className="fa-ul">
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={4} xs={12} className="text-center">
                            <div className="hexagon">
                                <div className="inner">
                                    <div className="center h5">
                                        Core Values
                                    </div>
                                </div>
                            </div>
                        </Col>
                        
                        <Col sm={4} xs={12} className="text-center">
                            <div className="hexagon">
                                <div className="inner">
                                    <div className="core-value-icon">
                                        <FontAwesomeIcon icon={faHandHoldingHeart} size="4x" color="#1778c6"/>
                                    </div>
                                    <div className="core-value-title">
                                        Be Respectful
                                    </div>
                                </div>
                            </div>
                            <div className="core-value-content content-right">
                                <ul className="fa-ul">
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-center">
                            <div className="hexagon">
                                <div className="inner">
                                    <div className="core-value-icon">
                                        <FontAwesomeIcon icon={faUserShield} size="4x" color="#1778c6"/>
                                    </div>
                                    <div className="core-value-title">
                                        Be Trustworthy
                                    </div>
                                </div>
                            </div>
                            <div className="core-value-content content-bottom">
                                <ul className="fa-ul">
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                    <li><span className="fa-li"><FontAwesomeIcon icon={faCheck} size="1x" color="#1778c6"/></span>Content</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default HexagoneCard