import React from 'react';
import '../../css/navigation.css';
import { Navbar, Nav} from 'react-bootstrap';
import VeracityLogo from '../../images/veracity.png';
class Navigation extends React.Component {
    render(){
        return (
            <Navbar expand="lg" variant="light">
                <Navbar.Brand href="#home">
                    <img className="header-logo" src={VeracityLogo} alt="Veracity InfoTech"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="#services">SERVICES</Nav.Link>
                        <Nav.Link href="#about-us">ABOUT US</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Navigation