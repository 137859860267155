import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import Navigation from './Navigation';
class Header extends React.Component {
    render(){
        return (
            <div id="header" className="header"> 
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Navigation/>
                        </Col>
                        <Col xs={12} sm={6} className="text-right pt-2">
                            <Row>
                                <Col xs={12}>
                                    <i className="fa fa-phone fa-1x"></i>+1 (804) 893-0516‬
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <i className="fa fa-envelope fa-1x"></i>info@veracityinfotech.com
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Header