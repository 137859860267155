import React from 'react';
import '../../css/about-us.css';
import WhyUs from './WhyUs';
import OurValues from './OurValues';

class AboutUs extends React.Component {
   
    render(){
        
        return (
            <div id="about-us" className="about-us">
                <h1 className="text-center">About Us</h1>
                <WhyUs/>
                <OurValues/>
            </div>
        )
    }
}

export default AboutUs