import React from 'react';
import { Container,Col,Row } from 'react-bootstrap';
import '../../css/footer.css';
import VeracityLogo from '../../images/veracity.png';

class Footer extends React.Component {
    render(){
        return (
            <div id="footer" className="footer">
                <Container fluid>
                    
                    <Row className="footer-contact">
                        <Col sm={4} className="text-center">
                            <img className="footer-logo" src={VeracityLogo} alt="Veracity InfoTech"/>
                        </Col>
                        
                        <Col sm={4}>
                            <p>Virginia</p>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <i className="fa fa-map-marker pl-1 pr-4 pt-3 fa-2x"></i><span>516 Siena Ln, Glen Allen, VA 23059, USA</span>
                                </li>
                                <li>
                                    <i className="fa fa-phone pr-3 pt-3 fa-2x"></i> <span>+1 (804) 893-0516‬</span>
                                </li>
                                <li>
                                    <i className="fa fa-envelope pr-3 pt-3 fa-2x"></i><span>info@veracityinfotech.com</span>
                                </li>
                            </ul>
                        </Col>
                        <Col sm={4}>
                            <p>FOLLOW US ON</p>
                            <a href="/" className="social-icon"><i className="fa fa-facebook  fa-2x"></i></a>
                            <a href="/" className="social-icon"><i className="fa fa-twitter  fa-2x"></i></a>
                            <a href="/" className="social-icon"><i className="fa fa-google-plus fa-2x"></i></a>
                            <a href="/" className="social-icon"><i className="fa fa-linkedin fa-2x"></i></a>
                            <a href="/" className="social-icon"><i className="fa fa-instagram fa-2x"></i></a>
                            <a href="/" className="social-icon"><i className="fa fa-youtube fa-2x"></i></a>
                        </Col>
                    </Row>
                    
                    <Row className="footer-fix">
                        <Col sm={12}> 
                            <div className="footer-copyright">
                                Veracity InfoTech LLC. All Rights Reserved © 2020	
                            </div>
                        </Col>
                    </Row> 
                </Container>
                
            </div>
        )
    }
}

export default Footer