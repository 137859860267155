import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'animate.css/animate.min.css';
import Header from '../js/components/Header';
import SiteCarousel from '../js/components/SiteCarousel';
import Footer from '../js/components/Footer';
import Services from '../js/pages/Services';
import AboutUs from '../js/pages/AboutUs';
import '../css/main.css';


function SinglePageApp() {
  return (
    <div id="single-page-app-container" className="single-page-app-container">
        <Header/>
        <SiteCarousel/>
        <Services/>
        <AboutUs/>
        <Footer/>
    </div>
  );
}

export default SinglePageApp;
